<template>
  <div v-if="$store.getters.ready">
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row justify-content-md-center mt-3">
            <div class="col-md-8 col-lg-6 col-xl-5">

              <div class="widget-holder rounded">
                <div class="widget-bg">
                  <div class="widget-body text-center">

                    <h2 class="mb-5 text-center">Reset Password</h2>

                    <div v-if="mode === 'email'">
                      <div class="form-group">
                        <label>Email Address</label>
                        <input type="email" v-model="form.email" class="form-control text-center" maxlength="254" required />
                      </div>
                      <div class="form-group mx-3 px-5 pt-3 mb-4">
                        <button type="button" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" @click="submitReset">Continue</button>
                      </div>
                    </div>
                    <div v-if="mode === 'check'">
                      <h4 class="mt-2 mb-0"><i class="fal fa-envelope-open-text fa-5x text-stroke-5 text-icon-gray"></i></h4>
                      <p class="mt-4 fs-18 fw-300 heading-font-family">A password reset link has been sent to your email address. Follow the link to reset your password.</p>
                    </div>

                    <div v-if="mode === 'password'">
                      <div class="form-group">
                        <label for="newpasswd">New Password</label>
                        <input type="password" v-model="form.new" class="form-control text-center" maxlength="254" />
                      </div>
                      <div class="form-group">
                        <label for="confirmpasswd">Confirm New Password</label>
                        <input type="password" v-model="form.confirm" class="form-control text-center" maxlength="254" />
                      </div>
                      <div class="form-group mx-3 px-5 pt-3 mb-4">
                        <button type="button" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" @click="submitPassword">Continue</button>
                      </div>
                    </div>
                    <div v-if="mode === 'success'">
                      <h4 class="mt-2 mb-0"><i class="fal fa-check-circle fa-5x text-stroke-5 text-icon-gray"></i></h4>
                      <p class="mt-4 fs-18 fw-300 heading-font-family">Your password has been updated.</p>
                      <div class="form-group mx-3 px-5 pt-3 mb-4">
                        <router-link to="/login" role="button" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple">Log In</router-link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      mode: 'email', // email, check, password, success
      form: {
        email: '',
        code: '',
        new: '',
        confirm: '',
      },
    };
  },
  methods: {
    submitReset() {
      this.$api.post('/profile/password/reset', this.form)
        .then(() => {
          this.mode = 'check';
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    submitPassword() {
      if (this.form.new !== this.form.confirm) {
        toast.danger(this, 'Passwords do not match');
        return;
      }
      this.$api.patch('/profile/password/update', this.form)
        .then(() => {
          this.mode = 'success';
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    if (this.$route.query.email && this.$route.query.email.includes('@')) {
      this.form.email = this.$route.query.email;
    }
    if (this.$route.query.code) {
      if (!this.$route.query.email || !this.$route.query.code) {
        this.$store.commit('error', 404);
        return;
      }
      // verify code
      try {
        await this.$api.patch('/profile/password/update', { email: this.$route.query.email, code: this.$route.query.code });
        this.mode = 'password';
        this.form.email = this.$route.query.email;
        this.form.code = this.$route.query.code;
      } catch {
        this.$store.commit('error', 404);
        return;
      }
    }
    this.$store.commit('ready', true);
  },
  components: {
    Header,
  },
};
</script>
